import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import {HttpClient} from '@angular/common/http'
import { HttpHeaders } from '@angular/common/http';
import { DataService } from './data.service';
import * as xml2js  from "xml2js";
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class ChmsService {

  constructor( private http: HttpClient,
    public dataService: DataService,) { }
getUserGroup(id){
  let self = this
  let userId =firebase.auth().currentUser.uid;
  return new Promise(function(resolve, reject) {
    let endpoint ="srv=individual_groups&individual_id="+id;
   return self.QuerryAPI(endpoint).then((result)=>{
      let persons =result["ccb_api"]["response"][0]["individuals"][0]
      if(persons.hasOwnProperty('individual')){
        let groups =persons["individual"][0]["groups"][0]["group"];
        //self.dataService.allGroups().valueChanges().subscribe((fbgroups) => 
        firebase.database().ref("/groups")
        .once('value').then((data) => {
        let  fbgroups = data.val();
          for (var key in fbgroups) {
            if(fbgroups[key].hasOwnProperty('chms')){
              groups.forEach(gp => {
                  if(gp["id"]==fbgroups[key]["chms"]) {
                        let members = self.addOrUpdateMember(userId, fbgroups[key]['members'])
                        self.dataService.groups(key).update({
                          members: members,
                        }).then(() => {});
                        self.dataService.groupAddNotify(userId,key).then(()=>{})
                  }
              });
            }
          }
       /////
        });
 
       
        let data ={
         
        }
        return resolve(data );
      } else 
     return  resolve("")
    })
  }); 
}
addOrUpdateMember(member,memberslist) {
  if (!memberslist) {
    memberslist = [member];
  } else {
    var index = -1;
    for (var i = 0; i < memberslist.length; i++) {
      if (memberslist[i] == member) {
        index = i;
      }
    }
    if (index > -1) {
      memberslist[index] = member;
    } else {
      memberslist.push(member);
    }
  }
  return memberslist;
}

  getUserName(phone) {
    let self = this
    return new Promise(function(resolve, reject) {
      let endpoint ="srv=individual_search&phone="+phone;
     return self.QuerryAPI(endpoint).then((result)=>{
        let persons =result["ccb_api"]["response"][0]["individuals"][0]
        if(persons.hasOwnProperty('individual')){
          let personId =persons["individual"][0]["$"].id
          let fullname =persons["individual"][0]["full_name"][0];
          let data ={
            fullname,
            personId
          }
          return resolve(data );
        } else 
       return  resolve("")
      })
    });
  }
  QuerryAPI(endpoint) {
    let self = this;
 return new Promise(function(resolve, reject) {
  let data ={
    "authkey":"fr0k3LDQTHTze2IUYtAY0g9enh2A5b3Y",
    "url":"https://wcimd.ccbchurch.com/api.php?"+endpoint
  }
    self.http.post("https://chat.wcimd.com/api/index.php",data, { responseType: "text" } ).subscribe((data)=>{
   xml2js.parseStringPromise(data /*, options */).then(function (result) {
   return resolve (result);
  })
  .catch(function (err) {
    reject(err)
  });
 
    })
  });
 
  /*console.log("#******************");
  this.http2.setDataSerializer('utf8');
  this.http2.useBasicAuth('chatsapp', '3~QVQV&Um+NA`4d%');
  this.http2.get('https://wcimd.ccbchurch.com/api.php?srv=individual_search&phone=2027665496', {}, {})
    .then(data => {
      let self = this;
      //console.log(data)
    parseString(data, function (err, result) {
      self.name =result["ccb_api"]["response"][0]["individuals"][0]["individual"][0]["full_name"][0];
      console.dir(result["ccb_api"]["response"][0]["individuals"][0]["individual"][0]);
      console.dir(result["ccb_api"]["response"][0]["individuals"][0]["individual"][0]["full_name"][0]);
      console.dir(result["ccb_api"]["response"][0]["individuals"][0]["individual"][0]["image"][0]);
    });
      console.log(data.status);
      console.log(data.data); // data received by server
      console.log(data.headers);
  
    })
    .catch(error => {
  
      console.log(error.status);
      console.log(error.error); // error message as string
      console.log(error.headers);
  
    });*/
  }
}
