export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyCcz4MJzuCYKTN4DleFfgzwSdJ44VSHUBY",
    authDomain: "wcimdgroupchat.firebaseapp.com",
    databaseURL: "https://wcimdgroupchat-default-rtdb.firebaseio.com",
    projectId: "wcimdgroupchat",
    storageBucket: "wcimdgroupchat.appspot.com",
    messagingSenderId: "748198449613",
    appId: "1:748198449613:web:c3491500d1cf799c72b484",
    measurementId: "G-WTGPR4TYPF"
  }
};
